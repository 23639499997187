import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

import { Content as ContentComponent, ContentProps } from './components/Content/Content'
import { Images as ImagesComponent, ImagesProps } from './components/Images/Images'

export type BannerProps = ContentProps & ImagesProps

export const Banner: React.FC<BannerProps> = ({
  aside,
  currentIndex,
  description,
  images,
  onCtaClick,
  onPaginationChange,
  title,
}) => (
  <>
    <Content aside={aside} description={description} onCtaClick={onCtaClick} title={title} />
    <Images currentIndex={currentIndex} images={images} onPaginationChange={onPaginationChange} />
  </>
)

const Content = styled(ContentComponent)`
  ${column([2, 4])};
  margin-bottom: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    margin-bottom: ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
    margin-bottom: 0;
    padding-top: ${g(1)};
  }
`

const Images = styled(ImagesComponent)`
  ${column([1, 6])}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([8, 5])}
  }
`
