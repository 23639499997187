import { AvisBloc } from '@pretto/zen/reveal/main/avisBloc/AvisBloc/AvisBloc'

import BANKS from '@pretto/config/banks.json'
import { BANKS_NUMBER } from '@pretto/website/src/config/reassurance'
import { BlocActu } from '@pretto/website/src/templates/home/components/blocActu/BlocActu/BlocActu'
import { BlocBanks } from '@pretto/website/src/templates/home/components/blocBanks/BlocBanks'
import { BlocFaq } from '@pretto/website/src/templates/home/components/blocFaq/BlocFaq'
import { BlocOutils } from '@pretto/website/src/templates/home/components/blocOutils/BlocOutils'
import { BlocParole } from '@pretto/website/src/templates/home/components/blocParole/BlocParole/BlocParole'
import { Courtier } from '@pretto/website/src/templates/home/components/courtier/Courtier'
import { HomeBlocs } from '@pretto/website/src/templates/home/homeBlocs/HomeBlocs'
import { useTrustScore } from '@pretto/website/src/utilities'

import { navigate } from '@reach/router'
import { graphql } from 'gatsby'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { formatArticlesDate } from './lib/formatArticlesDate'

export const Home = ({ data, rates }) => {
  const { recentPosts } = data.template.home

  const { score, numberOfReviews, reviews } = useTrustScore()

  useEffect(() => {
    setHomePath()
  }, [])

  const goToApp = () => {
    navigate('https://app.pretto.fr')
  }

  const setHomePath = () => {
    const homePath = window.location.pathname
    Cookies.set('homePath', homePath)
  }

  const handleCtaClick = () => {
    goToApp()
  }

  const courtierProps = {
    aside: 'Des courtiers pas comme les autres :',
    description:
      'Un courtier expert, c’est bien. Un courtier expert et pédagogue qui vous comprend, c’est mieux. Les courtiers Pretto vous accompagnent dans votre recherche de crédit immobilier avec implication et réactivité.',
    images: [
      'v1677667256/website/page/home/BlocCourtier-1.jpg',
      'v1677668879/website/page/home/BlocCourtier-2.jpg',
      'v1677668879/website/page/home/BlocCourtier-3.jpg',
    ],
    onCtaClick: handleCtaClick,
    rtb: [
      'Des courtiers régulièrement formés aux dernières réglementations en vigueur et spécialisés par type de projet.',
      'Derrière votre expert attitré, des outils puissants et toute une équipe qui œuvre pour votre projet.',
      'Nos courtiers engagés sont à votre écoute et toujours joignables (SMS, email et téléphone).',
    ],
    title: 'Leur priorité c’est vous !',
  }

  const blocOutilsProps = {
    aside: 'La transparence selon Pretto',
    description:
      'Nous partageons toutes les informations en notre possession afin de garantir une relation transparente et des décisions éclairées.',
    slides: [
      {
        title: 'Notre simulateur',
        description:
          'La bonne offre au bon moment : notre simulateur doté d’algorithmes puissants compare en profondeur les offres des banques et identifie la meilleure pour vous.',
        src: 'v1700128008/website/page/home/Re%CC%81sultat.png',
      },
      {
        title: 'Définissez votre stratégie bancaire.',
        description:
          'Nos courtiers vous partagent leurs outils de travail et choisissent à vos côtés l’offre de prêt qui vous correspond le mieux, selon 12 critères bancaires différents.',
        src: 'v1677658638/website/page/home/ZouClient.png',
      },
      {
        title: 'Suivez votre dossier en temps réel',
        description:
          'Vous nous confiez votre projet, vous devez savoir où il en est. Accord de principe, édition de l’offre... Bénéficiez d’un espace en ligne sécurisé pour suivre l’avancée de votre dossier en toute autonomie.',
        src: 'v1677658638/website/page/home/EspaceClient.png',
      },
    ],
    onCtaClick: handleCtaClick,
    title: 'Tout ce qu’on sait, vous le savez.',
  }

  const blocParoleProps = {
    aside: 'Nos engagements',
    description:
      'Chez Pretto, toutes nos équipes et nos +50 courtiers experts s’investissent avec passion et dévouement pour vous.\nNotre mission : instaurer la confiance dans la recherche de crédit immobilier.',
    imagePath: 'v1677667257/website/page/home/BlocParole.jpg',
    items: [
      {
        description:
          'Chez Pretto on vous dit tout dès le début. Nos prix sont fixes, connus à l’avance et les mêmes pour tous.',
        title: 'Le juste prix n’est pas un jeu',
      },
      {
        description:
          'Toute discrimination directe ou indirecte sur un dossier est absolument bannie chez Pretto. Nous nous engageons à présenter votre dossier en banque de manière équitable.',
        title: 'Pas de ça chez nous',
      },
      {
        description:
          'Chez Pretto, nous sommes transparents quant à l’ensemble de nos démarches et nous nous engageons à vous faire part des meilleures offres proposées par nos banques partenaires.',
        title: 'Nous sommes transparents',
      },
    ],
    title: `On n'a qu’une parole.`,
  }

  const banksShown = ['CASDEN', 'BNP', 'CEAPC', 'CAAP', 'Société Générale', 'LBP']

  const blocBanksProps = {
    title: `${BANKS_NUMBER.toString()}+ Banques partenaires`,
    description:
      'Le grand nombre et la qualité des dossiers que nous négocions nous ont valu de construire des relations de confiance avec les banques. Nous avons un accès privilégié aux banques et cela nous permet de prioriser votre dossier.',
    logos: banksShown.reduce((previous, label) => {
      const bank = BANKS.find(({ short_label }) => short_label === label)

      if (!bank) {
        return previous
      }

      return [...previous, bank.logo.regular]
    }, []),
  }

  const avisBlocProps = {
    description: 'Comme eux, n’attendez plus demain pour concrétiser vos projets d’avenir.',
    hat: 'Qui pourrait en parler mieux ?',
    reviews,
    title: 'Emprunteurs heureux',
    trustpilotRateProps: {
      numberOfReviews,
      score,
    },
  }

  const blocActuProps = {
    aside: 'Pour aller plus loin...',
    href: '/actualites/',
    posts: formatArticlesDate(recentPosts),
    title: 'L’actu immo par Pretto.',
  }

  const faqProps = {
    surtitle: data.template.home.homeFaqSurtitle,
    title: data.template.home.homeFaqTitle,
    questions: data.template.home.homeFaqQuestions,
  }

  return (
    <>
      <HomeBlocs title={data.title} {...data.template.home_rows} rates={rates} />
      <BlocOutils {...blocOutilsProps} />
      <Courtier {...courtierProps} />
      <BlocParole {...blocParoleProps} />
      <BlocBanks {...blocBanksProps} />
      <AvisBloc {...avisBlocProps} />
      <BlocActu {...blocActuProps} />
      <BlocFaq {...faqProps} />
    </>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      home: PropTypes.shape({
        banks: PropTypes.arrayOf(PropTypes.string).isRequired,
        homeFaqSurtitle: PropTypes.string,
        homeFaqTitle: PropTypes.string.isRequired,
        homeFaqQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
        pressReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
        recentPosts: PropTypes.arrayOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
      home_rows: PropTypes.object,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  rates: PropTypes.object,
}

export const fragment = graphql`
  fragment HomeTemplate on WpTemplate_Home {
    home {
      banks {
        slug
      }
      homeFaqSurtitle
      homeFaqTitle
      homeFaqQuestions {
        question: homeFaqQuestionsQuestion
        answer: homeFaqQuestionsAnswer
      }
      recentPosts {
        ... on WpPost {
          date
          excerpt
          id
          modified
          postTypeMeta {
            highlightedImage: highlightedimage
          }
          title
          uri
        }
      }
      pressReviews {
        logo
        title
        url
      }
      title: homeHeroTitle
    }
  }
`
