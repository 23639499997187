import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { CheckBold } from '@pretto/picto'

import nl2br from 'react-nl2br'
import styled from 'styled-components'

export interface BlocParoleItem {
  description: string
  title: string
}

export interface BlocParoleProps extends React.HTMLAttributes<HTMLDivElement> {
  aside: string
  description: string
  items: BlocParoleItem[]
  imagePath: string
  title: string
}

const Bloc = styled.section`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.accent3};

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-rows: auto 1fr;
    height: ${g(112)};
  }
`

const BlocDescription = styled.p`
  ${({ theme }) => theme.typos.body4};
`

const BlocHat = styled.aside`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

const BlocTitle = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const ImageContainer = styled.div`
  ${column([1, 6])};
  height: ${g(31)};
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 7])};
    border-bottom-right-radius: ${g(1)};
    border-top-right-radius: ${g(1)};
    height: ${g(43)};
    margin-bottom: ${g(-11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: auto;
    margin-bottom: ${g(-7)};
  }
`

const Image = styled(ImageComponent).attrs({
  size: `(min-width: ${breakpoints.laptop}) 50vw, (min-width: ${breakpoints.tablet}) 75vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const Main = styled.div`
  ${column([2, 4])};
  margin-bottom: ${g(6)};
  padding-top: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    padding-top: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
    margin-bottom: ${g(10)};
    padding-top: ${g(12)};
  }
`

const List = styled.ul`
  ${column([2, 4])};
  margin-bottom: ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 4])};
    display: flex;
    flex-direction: column;
    grid-row: span 2;
    justify-content: center;
    margin-bottom: 0;
  }
`

const ListItem = styled.li`
  ${column([9, 4])};
  display: flex;
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(-7)};
  }

  & + & {
    margin-top: ${g(3)};

    @media screen and (min-width: ${breakpoints.laptop}) {
      margin-top: ${g(7)};
    }
  }
`

const ListItemBullet = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent2};
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: ${g(4)};
  justify-content: center;
  margin-top: ${g(-1 / 2)};
  width: ${g(4)};
`

const ListItemBulletCheck = styled(CheckBold)`
  height: ${g(1, 4)};
`

const ListItemTitleDescription = styled.p`
  ${({ theme }) => theme.typos.body4};
`

const ListItemTitle = styled.h3`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1)};
`

export const BlocParole: React.FC<BlocParoleProps> = ({ aside, description, imagePath, items, title, ...props }) => (
  <Bloc {...props}>
    <Main>
      <BlocHat>{aside}</BlocHat>
      <BlocTitle>{title}</BlocTitle>
      <BlocDescription>{nl2br(description)}</BlocDescription>
    </Main>

    <List>
      {items.map(({ description, title }, index) => (
        <ListItem key={index}>
          <ListItemBullet>
            <ListItemBulletCheck />
          </ListItemBullet>

          <div>
            <ListItemTitle>{title}</ListItemTitle>
            <ListItemTitleDescription>{description}</ListItemTitleDescription>
          </div>
        </ListItem>
      ))}
    </List>

    <ImageContainer>
      <Image alt={title} path={imagePath} />
    </ImageContainer>
  </Bloc>
)
