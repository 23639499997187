import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export const Bloc = styled.section`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
  padding: ${g(6)} 0 ${g(8)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0 ${g(11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(11)} 0 ${g(16)};
  }
`

export const TitleBloc = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    margin-bottom: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
    margin-bottom: 0px;
  }
`

export const Surtitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

export const QuestionsBloc = styled.ul`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    margin-top: ${g(4)};
  }
`

export const Couple = styled.li`
  display: flex;
  flex-direction: column;
  padding-left: ${g(3)};
  border-left: 1px solid ${({ theme }) => theme.colors.neutral2};

  & + & {
    margin-top: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: ${g(5)};

    & + & {
      margin-top: ${g(6)};
    }
  }
`

export const Question = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1)};
`

export const Answer = styled.div`
  ${({ theme }) => theme.typos.body4};
`
