import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { shorthash } from '@pretto/bricks/core/utility/hash'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export interface PostPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  excerpt: string
  date: string
  uri: string
  postTypeMeta: { highlightedImage: string }
  title: string
}

const Date = styled.aside`
  ${({ theme }) => theme.typos.caption};
  margin-bottom: ${g(1)};
`

const Excerpt = styled.p`
  ${({ theme }) => theme.typos.caption};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
`

const Image = styled(ImageComponent).attrs({
  sizes: `(min-width: ${breakpoints.tablet}) 25vw, (min-width: ${breakpoints.tablet}) 33vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
  ],
})`
  border-radius: ${g(1)};
  display: block;
  height: ${g(20)};
  margin-bottom: ${g(3)};
  object-fit: cover;
  width: 100%;
`

const Title = styled(Link)`
  ${({ theme }) => theme.typos.heading5};
  display: block;
  margin-bottom: ${g(2)};
`

export const PostPreview: React.FC<PostPreviewProps> = ({ excerpt, date, uri, postTypeMeta, title, ...props }) => {
  const id = shorthash(`PostPreview--${title}`)

  return (
    <article {...props}>
      <Link aria-labelledby={id} href={uri} tabIndex={-1}>
        <Image alt="" path={postTypeMeta.highlightedImage} />
      </Link>

      <Date>{date}</Date>
      <Title href={uri} id={id}>
        {title}
      </Title>
      <Excerpt>{excerpt}</Excerpt>
    </article>
  )
}
