import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { useState } from 'react'
import styled from 'styled-components'

import { Banner, BannerProps } from './components/Banner/Banner'
import { Rtb as RtbComponent, RtbProps } from './components/Rtb/Rtb'

export type CourtierProps = Pick<BannerProps, 'aside' | 'description' | 'images' | 'onCtaClick' | 'title'> &
  Pick<RtbProps, 'rtb'>

export const Courtier: React.FC<CourtierProps> = ({ aside, description, images, onCtaClick, rtb, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleEnd = (index: number) => {
    setCurrentIndex((index + 1) % 3)
  }

  const handlePaginationChange = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <Bloc>
      <Banner
        aside={aside}
        description={description}
        currentIndex={currentIndex}
        images={images}
        onCtaClick={onCtaClick}
        onPaginationChange={handlePaginationChange}
        title={title}
      />

      <Rtb currentIndex={currentIndex} duration={5} onEnd={handleEnd} rtb={rtb} />
    </Bloc>
  )
}

const Bloc = styled.section`
  ${grid()};
  padding: ${g(6)} 0 ${g(8)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0 ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(12)} 0 ${g(13)};
  }
`

const Rtb = styled(RtbComponent)`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])}
  }
`
