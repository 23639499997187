import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { SliderChildrenProps } from '@pretto/website/src/templates/home/components/blocOutils/components/Slider/Slider'
import {
  Description,
  Image,
  ImageContainer,
  Next,
  Previous,
  Slide,
  SlideContainer,
  Title,
} from '@pretto/website/src/templates/home/components/blocOutils/components/Slider/Slider.styles'

import styled from 'styled-components'

export const Tablet: React.FC<SliderChildrenProps> = ({ slides, activeIndex, onPreviousClick, onNextClick }) => {
  const { title, description } = slides[activeIndex]

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>

        <SlideContainer>
          {slides.map(({ title }, index) => (
            <Slide key={title} $isActive={index === activeIndex} />
          ))}
        </SlideContainer>
      </Content>
      <ImageController>
        <Previous disabled={activeIndex === 0} onClick={onPreviousClick} />
        <ImageContainer>
          {slides.map(({ src }, index) => (
            <Image key={src} path={src} $isActive={index === activeIndex} />
          ))}
        </ImageContainer>
        <Next disabled={activeIndex === slides.length - 1} onClick={onNextClick} />
      </ImageController>
    </Container>
  )
}

const Container = styled.div`
  ${column([2, 8])};
  overflow: hidden;
  ${grid(8, { isLeftAligned: true, isRightAligned: true })};
`

const Content = styled.div`
  ${column([2, 6])};
`

const ImageController = styled.div`
  ${column(8)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
