import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, Grid } from '@pretto/bricks/components/layout/Grid'

import { PrimaryButton } from '@pretto/zen/reveal/atoms/buttons/primaryButton/PrimaryButton'

import nl2br from 'react-nl2br'
import styled from 'styled-components'

import { Slider, SliderProps } from './components/Slider/Slider'

export interface BlocOutilsProps extends SliderProps {
  aside: string
  description: string
  onCtaClick: () => void
  title: string
}

export const BlocOutils: React.FC<BlocOutilsProps> = ({ aside, description, onCtaClick, title, slides, ...props }) => (
  <Container {...props}>
    <Content>
      <Transparency>{aside}</Transparency>
      <Title>{title}</Title>
      <Description>{nl2br(description)}</Description>
      <Simulate onClick={onCtaClick}>Simuler mon prêt</Simulate>
    </Content>
    <Slider slides={slides} />
  </Container>
)

const Container = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
`

const Content = styled.div`
  ${column([2, 4])};
  padding-top: ${g(6)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    padding-top: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    padding-top: ${g(12)};
  }
`

const Transparency = styled.p`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

const Title = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(5)};
  }
`

const Simulate = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.neutral3};
  margin-bottom: ${g(6)};
  max-width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(15)};
  }
`
