import { Rate } from '@pretto/website/src/components/Rates/RatesTable/types/Rate.type'
import { HeroPictureRateProps } from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/components/HeroPictureRate/HeroPictureRate'
import { RowBannerProps } from '@pretto/website/src/templates/home/homeBlocs/rows/rowBanner/components/RowBanner/RowBanner'
import { RowPromisesProps } from '@pretto/website/src/templates/home/homeBlocs/rows/rowPromises/components/RowPromises/RowPromises'

import loadable from '@loadable/component'
import { useMemo } from 'react'

const HeroPictureRateContainer = loadable(
  () => import('@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/HeroPictureRateContainer'),
  {
    resolveComponent: components => components.HeroPictureRateContainer,
  }
)

const RowBanner = loadable(
  () => import('@pretto/website/src/templates/home/homeBlocs/rows/rowBanner/components/RowBanner/RowBanner'),
  {
    resolveComponent: components => components.RowBanner,
  }
)

const RowPromises = loadable(
  () => import('@pretto/website/src/templates/home/homeBlocs/rows/rowPromises/components/RowPromises/RowPromises'),
  {
    resolveComponent: components => components.RowPromises,
  }
)

type HomeHerosProps = Omit<HeroPictureRateProps, 'title'> & {
  __typename: 'WpTemplate_Home_HomeRows_HomeHeros_HomeHerosPictureRate'
}

type HomeRowsProps =
  | (RowBannerProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanner' })
  | (RowPromisesProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsPromises' })

type HomeBlocsProps = {
  rates: Rate[]
  title: string
  homeHeros: HomeHerosProps[]
  homeRows: HomeRowsProps[]
}

export const HomeBlocs = ({ rates, title, homeHeros, homeRows }: HomeBlocsProps) => {
  const heroComponent = useMemo(() => {
    if (homeHeros?.[0]) {
      const { __typename, ...rowProps } = homeHeros[0]

      switch (__typename) {
        case 'WpTemplate_Home_HomeRows_HomeHeros_HomeHerosPictureRate':
          return <HeroPictureRateContainer title={title} rates={rates} {...rowProps} />

        default:
          return null
      }
    }
  }, [])

  const rowsComponents = useMemo(() => {
    return homeRows.map(({ __typename, ...moduleProps }, index) => {
      const rowProps = { ...moduleProps, key: index }

      switch (__typename) {
        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanner':
          return <RowBanner {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsPromises':
          return <RowPromises {...rowProps} />

        default:
          return null
      }
    })
  }, [])

  return [heroComponent, ...rowsComponents]
}
