import Image from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export interface BlocBanksProps {
  description: string
  logos: string[]
  title: string
}

export const BlocBanks: React.FC<BlocBanksProps> = ({ description, logos, title, ...props }) => (
  <Container {...props}>
    <Title>{title}</Title>
    <Description>{description}</Description>

    <Logos>
      {logos.map(logo => (
        <Logo key={logo}>
          <LogoImage path={logo} alt={logo} />
        </Logo>
      ))}
    </Logos>
  </Container>
)

const Container = styled.section`
  ${grid()};
  padding: ${g(5)} 0 ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(17)} 0 ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(13)} 0 ${g(12)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(15)} 0 ${g(12)};
  }
`

const Title = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  ${column([2, 4])};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
    ${column([3, 4])};
    align-self: center;
    margin-bottom: 0;
  }
`

const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    align-self: center;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral1};
    padding-left: ${g(5)};
  }
`

const Logos = styled.div`
  ${column([2, 4])};
  display: grid;
  column-gap: ${g(4)};
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${g(5)};
  row-gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    column-gap: ${g(7)};
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
    margin-top: ${g(8)};
  }
`

const Logo = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(2)};
  }
`

const LogoImage = styled(Image)`
  width: 100%;
`
