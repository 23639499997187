import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { ButtonInline } from '@pretto/zen/reveal/atoms/buttons/buttonInline/ButtonInline'

import styled from 'styled-components'

import { PostPreview, PostPreviewProps } from './components/PostPreview/PostPreview'

export interface BlocActuProps extends React.HTMLAttributes<HTMLDivElement> {
  aside: string
  href: string
  posts: (PostPreviewProps & { id: string })[]
  title: string
}

const Bloc = styled.section`
  ${grid()};
  overflow: hidden;
  padding: ${g(6)} 0 ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0 ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(12)} 0;
  }
`

const BlocAside = styled.aside`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

const BlocTitle = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(6)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const BlocHeader = styled.header`
  ${column([2, 4])};
  margin-bottom: ${g(6)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    margin-bottom: ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    margin-bottom: ${g(10)};
  }
`

const Posts = styled.div`
  ${column([1, 6])};
  display: grid;
  grid-column-gap: ${g(3)};
  grid-template-columns: repeat(3, calc(100% - ${g(6, 4)}));
  overflow-x: scroll;
  padding: 0 ${g(2, 4)};
  scroll-behavior: smooth;
  scroll-padding: ${g(2, 4)};
  scroll-snap-type: x mandatory;
  will-change: scroll-position;

  & > *:nth-child(4) {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    grid-template-columns: repeat(3, 1fr);
    overflow-x: unset;
    padding: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 12])};
    grid-template-columns: repeat(4, 1fr);

    & > *:nth-child(4) {
      display: unset;
    }
  }
`

const Post = styled(PostPreview)`
  scroll-snap-align: start;
`

export const BlocActu: React.FC<BlocActuProps> = ({ aside, href, posts, title, ...props }) => (
  <Bloc {...props}>
    <BlocHeader>
      <BlocAside>{aside}</BlocAside>
      <BlocTitle>{title}</BlocTitle>

      <ButtonInline href={href}>Voir tous les articles</ButtonInline>
    </BlocHeader>

    <Posts>
      {posts.map(props => (
        <Post key={props.id} {...props} />
      ))}
    </Posts>
  </Bloc>
)
