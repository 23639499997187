import * as S from './BlocFaq.styles'

type BlocFaqProps = {
  questions: { question: string; answer: string }[]
  title: string
  surtitle?: string
}

export const BlocFaq: React.FC<BlocFaqProps> = ({ questions, title, surtitle }) => {
  return (
    <S.Bloc itemScope itemType="https://schema.org/FAQPage">
      <S.TitleBloc>
        {surtitle && <S.Surtitle>{surtitle}</S.Surtitle>}
        <S.Title>{title}</S.Title>
      </S.TitleBloc>
      <S.QuestionsBloc>
        {questions.map(({ question, answer }) => (
          <S.Couple itemScope itemProp="mainEntity" itemType="https://schema.org/Question" key={question}>
            <S.Question itemProp="name">{question}</S.Question>
            <S.Answer itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
              <p itemProp="text">{answer}</p>
            </S.Answer>
          </S.Couple>
        ))}
      </S.QuestionsBloc>
    </S.Bloc>
  )
}
